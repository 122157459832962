import * as React from "react";
import { Link } from "gatsby";
import Header from "../components/Header";
import Footer from "../components/Footer";

import IntroHero from "../images/hero-june.jpg";
import serieBanner from "../images/sostenidos-por-jesus.jpg";
import Spacer from "../components/Spacer";
import UltimaSerie from "../components/UltimaSerie";
// markup
const IndexPage = () => {
  return (
    <>
      <Header></Header>

      <div
        className="image-block-wrapper-mobile"
        data-animation-role="image"
        data-animation-override=""
        id="yui_3_17_2_1_1633220115410_280"
      >
        <div className="image-hero-wrapper sqs-image-shape-container-element has-aspect-ratio">
          <noscript>
            <img src={IntroHero} alt="" />
          </noscript>
          <img
            className="image-hero thumb-image loaded"
            data-component-key="idv1.i.571e4d84e707eb97adfbf151"
            data-src={IntroHero}
            data-image={IntroHero}
            data-image-dimensions="2400x1600"
            data-image-focal-point="0.5,0.5"
            alt=""
            data-load="false"
            data-image-id="571e4d84e707eb97adfbf151"
            data-type="image"
            data-image-resolution="2500w"
            src={IntroHero}
          />
        </div>
      </div>
      <div className="Index-page-content home">
        <div className="row sqs-row">
          <div className="col sqs-col-12 span-12">
            <div
              className="image-block-wrapper hero-image"
              data-animation-role="image"
              data-animation-override=""
              id="yui_3_17_2_1_1633220115410_280"
            >
              <div className="image-hero-wrapper sqs-image-shape-container-element has-aspect-ratio">
                <noscript>
                  <img src={IntroHero} alt="" />
                </noscript>
                <img
                  className="image-hero thumb-image loaded"
                  data-component-key="idv1.i.571e4d84e707eb97adfbf151"
                  data-src={IntroHero}
                  data-image={IntroHero}
                  data-image-dimensions="2400x1600"
                  data-image-focal-point="0.5,0.5"
                  alt=""
                  data-load="false"
                  data-image-id="571e4d84e707eb97adfbf151"
                  data-type="image"
                  data-image-resolution="2500w"
                  src={IntroHero}
                />
              </div>
            </div>
          </div>
        </div>

        <Spacer hideMobile></Spacer>

        <div className="servicio-banner">
          <span>
            <a
              href="https://www.google.com/maps/place/10298+NW+46th+St,+Sunrise,+FL+33351/@26.18143,-80.2892437,17z/data=!3m1!4b1!4m5!3m4!1s0x88d908ad823ba101:0xa97fe5df8fd4965e!8m2!3d26.1814252!4d-80.287055"
              target="_blank"
              rel="noreferrer"
            >
              10298 NW 46TH ST SUNRISE FL 33351
            </a>{" "}
          </span>
          - DOMINGOS 10:45 AM
        </div>
        <Spacer></Spacer>

        <UltimaSerie></UltimaSerie>

        <Spacer></Spacer>

        <div className="row sqs-row">
          <div className="col sqs-col-4 span-4">
            <div
              className="sqs-block html-block sqs-block-html"
              data-block-type="2"
              id="block-yui_3_17_2_24_1454532196246_52303"
            >
              <div className="sqs-block-content">
                <h2>Visión</h2>
              </div>
            </div>
          </div>
          <div className="col sqs-col-8 span-8">
            <div
              className="sqs-block html-block sqs-block-html"
              data-block-type="2"
              id="block-yui_3_17_2_21_1454534841244_32856"
            >
              <div className="sqs-block-content">
                <p className="text-block">
                  Somos una comunidad de discípulos que buscan ver vidas
                  transformadas por el evangelio de Cristo para que impacten su
                  familia, iglesia y comunidad.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="sqs-block horizontalrule-block sqs-block-horizontalrule">
          <div className="sqs-block-content">
            <hr />
          </div>
        </div>
        <div className="row sqs-row">
          <div className="col sqs-col-4 span-4">
            <div
              className="sqs-block html-block sqs-block-html"
              data-block-type="2"
              id="block-yui_3_17_2_24_1454532196246_52303"
            >
              <div className="sqs-block-content">
                <h2>Misión</h2>
              </div>
            </div>
          </div>
          <div className="col sqs-col-8 span-8">
            <div
              className="sqs-block html-block sqs-block-html"
              data-block-type="2"
              id="block-yui_3_17_2_21_1454534841244_32856"
            >
              <div className="sqs-block-content">
                <p className="text-block">
                  Deseamos atesorar y proclamar la gloria de Dios en Cristo por
                  medio de su Espíritu a las naciones.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacer></Spacer>

      <Footer></Footer>
    </>
  );
};

export default IndexPage;
