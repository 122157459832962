import React from 'react'
import { Link } from "gatsby";

import IntroHero from "../images/herobw2.jpg";
import serieBanner from "../images/series/serie-junio30.jpeg";
import Spacer from "../components/Spacer";

const UltimaSerie = () => {
  return (
    <div className="sqs-block image-block sqs-block-image sqs-text-ready nueva-serie">
      <div className="sqs-block-content" id="yui_3_17_2_1_1633223333612_186">
        <figure
          className="
          sqs-block-image-figure
          image-block-outer-wrapper image-block-v2
          design-layout-card
          combination-animation-none
          individual-animation-none individual-text-animation-none
          image-position-left image-linked
          sqs-text-ready
        "
          data-scrolled=""
          data-test="image-block-v2-outer-wrapper"
          id="yui_3_17_2_1_1633223333612_185"
        >
          <div className="intrinsic" id="yui_3_17_2_1_1633223333612_184">
            <div
              className="image-inset"
              data-animation-role="image"
              data-description=""
              id="yui_3_17_2_1_1633223333612_183"
            >
              <div className="sqs-image-shape-container-element content-fit">
                <noscript>
                  <img
                    src={serieBanner}
                    alt="Sostenidos por Jesus"
                    loading="lazy"
                  />
                </noscript>
                <Link to="/predicas">
                  <img
                    data-component-key="idv1.i.52ed63b1e4b04368c021b921"
                    data-src={serieBanner}
                    data-image={serieBanner}
                    data-image-dimensions="959x719"
                    data-image-focal-point="0.5,0.5"
                    loading="lazy"
                    data-parent-ratio="16.1"
                    alt="Transient"
                    className="loaded"
                    data-image-resolution="1000w"
                    src={serieBanner}
                  />
                  <div className="image-overlay"></div>
                </Link>

              </div>
            </div>
          </div>

          <figcaption className="image-card-wrapper" data-width-ratio="">
            <div className="image-card sqs-dynamic-text-container">
              <div className="image-title-wrapper">
                <div
                  className="image-title sqs-dynamic-text"
                  data-width-percentage="28"
                >
                  <Link to="/predicas">
                    <p>Domingo Junio 30:</p>
                  </Link>
                </div>
              </div>

              <div className="image-subtitle-wrapper">
                <div
                  className="image-subtitle sqs-dynamic-text"
                  data-width-percentage="28"
                >
                  <h2 className="serieTitle">Sabiduria para elegir Mejor</h2>
                  <p>
                    Serie: "La Sabiduria del Señor para mi dia a dia"
                  </p>
                  <p>Proverbios 4:10-19</p>
                </div>
              </div>

              <div className="image-button-wrapper">
                <div
                  className="image-button sqs-dynamic-text"
                  data-width-percentage="28"
                >
                  <div className="image-button-inner min-font-set">
                    <a href="https://www.google.com/maps/place/Gracia+Sobre+Gracia/@26.18143,-80.2896299,17z/data=!3m1!4b1!4m6!3m5!1s0x88d90996c95c4ca3:0x9688e39949658d1b!8m2!3d26.1814252!4d-80.287055!16s%2Fg%2F11kkzpslqd?entry=ttu" target='_blank'>Visitanos</a>
                  </div>
                </div>
              </div>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
  )
}

export default UltimaSerie;